import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as styles from "./index.module.css";
import { withAuthentication } from "../../hoc/withAuthentication";
import PublicAddress from "../../components/publicAddress";
import Main from "../../components/main";
import ReportOverlay from "../../components/reportOverlay";
import ContentArea from "../../components/contentArea";
import Toolbar from "../../components/toolbar";
import NotFoundPage from "../../components/notFoundPage";
import TextArea from "../../components/textArea";
import ToolbarSection from "../../components/toolbarSection";
import Button from "../../components/button";
import IconButton from "../../components/iconButton";
import DropdownMenu from "../../components/dropdownMenu";
import SocialSection from "../../components/socialSection";
import SocialButton from "../../components/socialButton";
import Avatar from "../../components/avatar";
import MasonryTiles from "../../components/masonryTiles";
import AccountVerifyOverlay from "../../components/accountVerifyOverlay";
import Tile from "../../components/tile";
import MidPageHeader from "../../components/midPageHeader";
import MissingDataPanel from "../../components/missingDataPanel";
import ListWithButtons from "../../components/listWithButtons";
import ScreenLoad from "../../components/screenLoad";
import LinkWithIcon from "../../components/linkWithIcon";
import NavPanel from "../../components/navPanel";
import Statistics from "../../components/statistics";
import Consts from "../../config/consts";
import Api from "../../config/api";
import Common from "../../config/common";
import Util from "../../config/util";
import { faEnvelope, faHeart } from "@fortawesome/free-regular-svg-icons";
import {
    faCheck,
    faBars,
    faFlag,
    faShareNodes,
    faGlobe,
    faHeart as faHeartSolid,
    faShieldAlt,
    faPencilAlt,
    faPlus,
    faRotate,
    faThLarge,
    faLink,
    faAngleDoubleDown,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faTwitter, faSnapchatGhost, faInstagram, faLinkedinIn, faTiktok, faTelegramPlane, faDiscord, faEthereum, faBitcoin } from "@fortawesome/free-brands-svg-icons";
import image_drips_tall from "../../images/bgroovia_drips_tall.png";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED],
        redirectPage: "/",
    },
    class User extends React.Component {
        // export default class Home extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();
            this.created_section_ref = React.createRef();
            this.owned_section_ref = React.createRef();
            this.collection_section_ref = React.createRef();
            this.hidden_section_ref = React.createRef();

            // Grab the query params from the url
            //const params = new URLSearchParams(props.location.search);
            this.state = {
                windowSize: window.innerWidth,
                initialising: true,
                profile: null,
                thisIsMyProfile: false,
                isBgrooviaProfile: false,
                hasContent: false,

                showAccountVerification: false,
                showReportOverlay: false,

                itemsCreated: [],
                itemsOwned: [],
                itemsCollection: [],
                itemsHidden: [],
                itemsMerch: [],

                fetchingCreated: false,
                fetchingOwned: false,
                fetchingCollection: false,
                fetchingHidden: false,
                fetchingMerch: false,

                hasFetchedCreated: false,
                hasFetchedOwned: false,
                hasFetchedCollection: false,
                hasFetchedHidden: false,
                hasFetchedMerch: false,

                hasMoreCreated: true,
                hasMoreOwned: true,
                hasMoreCollection: true,
                hasMoreHidden: true,
                hasMoreMerch: true,

                fetchQuantityCreated: 20,
                fetchQuantityOwned: 20,
                fetchQuantityCollection: 20,
                fetchQuantityHidden: 20,
                fetchQuantityMerch: 20,

                isFollowing: false,
                hasGotFollow: false,
                isUpdatingFollow: false,
                // followingCount: 0,
                followerCount: 0,
            };
        }

        componentDidMount() {
            let resize_timeout = null;
            window.onresize = (val) => {
                clearTimeout(resize_timeout);
                resize_timeout = setTimeout(() => {
                    this.setState({
                        windowSize: window.innerWidth,
                    });
                }, 200);
            };

            // Grab the profile first
            this.fetchProfile()
                .then(() => {
                    // Grab the profiles content
                    Promise.all([this.fetchCreated(), this.fetchOwned(), this.fetchCollection(), this.fetchHidden(), this.fetchMerch()])
                        .then(() => {
                            this.setState({
                                initialising: false,
                                hasContent: this.state.itemsCreated.length > 0 || this.state.itemsOwned.length > 0 || this.state.itemsCollection.length > 0 || this.state.itemsHidden.length > 0,
                            });
                        })
                        .catch((e) => {
                            console.error(e);
                        })
                        .finally(() => {
                            this.setState({
                                initialising: false,
                            });
                        });

                    // At the same time lets also grab the current state of the follow
                    if (this.props.auth.user && !this.props.auth.user.profiles.some((p) => p.displayName.toLowerCase() === this.props.params.displayname.toLowerCase())) {
                        Api.market({
                            endpoint: "/user/follow",
                            method: "GET",
                            data: {
                                displayName: this.props.params.displayname,
                            },
                        })
                            .then((res) => {
                                this.setState({
                                    hasGotFollow: true,
                                    isFollowing: res.following === true,
                                });
                            })
                            .catch((e) => {});
                    }
                })
                .catch((e) => {
                    console.error(e);
                    this.setState({
                        initialising: false,
                    });
                });
        }

        refreshPage = () => {
            window.location.reload();
        };

        onShareFacebook = () => {};
        onShareByEmail = () => {
            const url = window.location.href;
            const subject = encodeURIComponent(`Check Out @${this.props.params.displayname} on B Minted!`);
            const message = encodeURIComponent(
                `Hi! :)\n\nI saw this profile and thought of you! Thought you might like to check it out; its a profile owned by '@${this.props.params.displayname}' and it has some cool things in it! You can check it out yourself at:\n\n${url}\n`
            );
            window.location.href = `mailto:?subject=${subject}&body=${message}`;
        };
        onShareByUrl = () => {
            navigator.clipboard.writeText(window.location.href);
            Util.notify.info("Copied profile url to your clipboard.");
        };

        toggleFollow = () => {
            if (this.state.hasGotFollow && !this.state.isUpdatingFollow) {
                let old_follow = this.state.isFollowing === true;
                let new_follow = !old_follow;

                // Update the state
                this.setState(
                    {
                        isUpdatingFollow: true,
                        isFollowing: !old_follow,
                        followerCount: Math.max(0, new_follow ? this.state.followerCount + 1 : this.state.followerCount - 1),
                    },
                    () => {
                        // Once state is updated lets now update the DB
                        Api.market({
                            endpoint: "/user/follow",
                            method: "PATCH",
                            data: {
                                displayName: this.props.params.displayname,
                                state: new_follow ? "FOLLOWING" : "UNFOLLOWED",
                            },
                        })
                            .then(() => {
                                this.refreshPage();
                                // this.setState({
                                //     isUpdatingFollow: false,
                                // });
                            })
                            .catch((e) => {
                                this.setState({
                                    isUpdatingFollow: false,
                                    isFollowing: old_follow,
                                    followerCount: this.state.followerCount - 1,
                                });
                            });
                    }
                );
            }
        };

        onSignIn = () => {
            Wallets;
        };

        fetchProfile = () => {
            return new Promise((resolve, reject) => {
                Api.market({
                    endpoint: "/user/profile",
                    method: "GET",
                    data: {
                        displayName: this.props.params.displayname,
                    },
                })
                    .then((profile) => {
                        this.setState(
                            {
                                profile: profile,
                                thisIsMyProfile: profile.owned === true,
                                isBgrooviaProfile: Common.objectPropertyExists(profile, "scheme", null) === "BGROOVIA",
                                //followingCount: Common.objectPropertyExists(this.props, "auth.user.followingCount", 0),
                                followerCount: Common.objectPropertyExists(profile, "followerCount", 0),
                            },
                            () => {
                                resolve();
                            }
                        );
                    })
                    .catch((e) => {
                        this.setState(
                            {
                                profile: null,
                            },
                            () => {
                                reject();
                            }
                        );
                    });
            });
        };

        fetchCreated = () => {
            return new Promise((resolve, reject) => {
                if (!this.state.fetchingCreated && this.state.hasMoreCreated) {
                    this.setState(
                        {
                            fetchingCreated: true,
                        },
                        () => {
                            // Make an api call to the back-end to get the currently viewed items
                            Api.market({
                                endpoint: "/content/created",
                                method: "GET",
                                data: {
                                    displayName: this.props.params.displayname,
                                    startIndex: this.state.itemsCreated.length,
                                    quantity: this.state.fetchQuantityCreated,
                                },
                            })
                                .then((results) => {
                                    this.setState(
                                        {
                                            hasFetchedCreated: true,
                                            fetchingCreated: false,
                                            itemsCreated: [...this.state.itemsCreated, ...results.data],
                                            hasMoreCreated: results.data.length === this.state.fetchQuantityCreated,
                                        },
                                        () => {
                                            resolve();
                                        }
                                    );
                                })
                                .catch((e) => {
                                    this.setState(
                                        {
                                            hasFetchedCreated: true,
                                            fetchingCreated: false,
                                        },
                                        () => {
                                            resolve();
                                        }
                                    );
                                });
                        }
                    );
                } else {
                    resolve();
                }
            });
        };

        fetchOwned = () => {
            return new Promise((resolve, reject) => {
                if (!this.state.fetchingOwned && this.state.hasMoreOwned) {
                    this.setState(
                        {
                            fetchingOwned: true,
                        },
                        () => {
                            // Make an api call to the back-end to get the currently viewed items
                            Api.market({
                                endpoint: "/content/owned",
                                method: "GET",
                                data: {
                                    displayName: this.props.params.displayname,
                                    startIndex: this.state.itemsOwned.length,
                                    quantity: this.state.fetchQuantityOwned,
                                },
                            })
                                .then((results) => {
                                    this.setState(
                                        {
                                            hasFetchedOwned: true,
                                            fetchingOwned: false,
                                            itemsOwned: [...this.state.itemsOwned, ...results.data],
                                            hasMoreOwned: results.data.length === this.state.fetchQuantityOwned,
                                        },
                                        () => {
                                            resolve();
                                        }
                                    );
                                })
                                .catch((e) => {
                                    this.setState(
                                        {
                                            hasFetchedOwned: true,
                                            fetchingOwned: false,
                                        },
                                        () => {
                                            resolve();
                                        }
                                    );
                                });
                        }
                    );
                } else {
                    resolve();
                }
            });
        };

        fetchCollection = () => {
            return new Promise((resolve, reject) => {
                if (!this.state.fetchingCollection && this.state.hasMoreCollection) {
                    this.setState(
                        {
                            fetchingCollection: true,
                        },
                        () => {
                            // Make an api call to the back-end to get the currently viewed items
                            Api.market({
                                endpoint: "/content/collection",
                                method: "GET",
                                data: {
                                    displayName: this.props.params.displayname,
                                    startIndex: this.state.itemsCollection.length,
                                    quantity: this.state.fetchQuantityCollection,
                                },
                            })
                                .then((results) => {
                                    this.setState(
                                        {
                                            hasFetchedCollection: true,
                                            fetchingCollection: false,
                                            itemsCollection: [...this.state.itemsCollection, ...results.data],
                                            hasMoreCollection: results.data.length === this.state.fetchQuantityCollection,
                                        },
                                        () => {
                                            resolve();
                                        }
                                    );
                                })
                                .catch((e) => {
                                    this.setState(
                                        {
                                            hasFetchedCollection: true,
                                            fetchingCollection: false,
                                        },
                                        () => {
                                            resolve();
                                        }
                                    );
                                });
                        }
                    );
                } else {
                    resolve();
                }
            });
        };

        fetchHidden = () => {
            return new Promise((resolve, reject) => {
                if (!this.state.isBgrooviaProfile && !this.state.fetchingHidden && this.state.hasMoreHidden) {
                    this.setState(
                        {
                            fetchingHidden: true,
                        },
                        () => {
                            // Make an api call to the back-end to get the currently viewed items
                            Api.market({
                                endpoint: "/content/hidden",
                                method: "GET",
                                data: {
                                    displayName: this.props.params.displayname,
                                    startIndex: this.state.itemsHidden.length,
                                    quantity: this.state.fetchQuantityHidden,
                                },
                            })
                                .then((results) => {
                                    this.setState(
                                        {
                                            hasFetchedHidden: true,
                                            fetchingHidden: false,
                                            itemsHidden: [...this.state.itemsHidden, ...results.data],
                                            hasMoreHidden: results.data.length === this.state.fetchQuantityHidden,
                                        },
                                        () => {
                                            resolve();
                                        }
                                    );
                                })
                                .catch((e) => {
                                    this.setState(
                                        {
                                            hasFetchedHidden: true,
                                            fetchingHidden: false,
                                        },
                                        () => {
                                            resolve();
                                        }
                                    );
                                });
                        }
                    );
                } else {
                    resolve();
                }
            });
        };

        fetchMerch = () => {
            return new Promise((resolve, reject) => {
                if (Common.objectPropertyExists(this.state, "profile.scheme", null) === "BGROOVIA" && !this.state.fetchingHidden && this.state.hasMoreHidden) {
                    this.setState(
                        {
                            fetchingMerch: true,
                        },
                        () => {
                            resolve();
                            // // Make an api call to the back-end to get the currently viewed items
                            // Api.market({
                            //     endpoint: "/content/merch",
                            //     method: "GET",
                            //     data: {
                            //         displayName: this.props.params.displayname,
                            //         startIndex: this.state.itemsHidden.length,
                            //         quantity: this.state.fetchQuantityHidden,
                            //     },
                            // })
                            //     .then((results) => {
                            //         this.setState(
                            //             {
                            //                 hasFetchedHidden: true,
                            //                 fetchingHidden: false,
                            //                 itemsHidden: [...this.state.itemsHidden, ...results.data],
                            //                 hasMoreHidden: results.data.length === this.state.fetchQuantityHidden,
                            //             },
                            //             () => {
                            //                 resolve();
                            //             }
                            //         );
                            //     })
                            //     .catch((e) => {
                            //         this.setState(
                            //             {
                            //                 hasFetchedHidden: true,
                            //                 fetchingHidden: false,
                            //             },
                            //             () => {
                            //                 resolve();
                            //             }
                            //         );
                            //     });
                        }
                    );
                } else {
                    resolve();
                }
            });
        };

        onShowLogin = () => {
            if (this.main_ref) {
                this.main_ref.current.showLoginOverlay();
            }
        };

        onShowEmailVerify = () => {
            if (this.main_ref) {
                this.main_ref.current.showEmailVerifyOverlay();
            }
        };

        showAccountVerification = () => {
            this.setState({
                showAccountVerification: true,
            });
        };
        hideAccountVerificationOverlay = (refreshRequired) => {
            if (refreshRequired === true) {
                window.location.reload();
            } else {
                this.setState({
                    showAccountVerification: false,
                });
            }
        };

        showReportOverlay = () => {
            this.setState({
                showReportOverlay: true,
            });
        };
        hideReportOverlay = () => {
            this.setState({
                showReportOverlay: false,
            });
        };

        onSyncWithChain = () => {
            Api.market({
                endpoint: "/chain/sync/user",
                method: "POST",
                data: {},
            })
                .then(() => {
                    Promise.all([this.fetchCreated(), this.fetchOwned(), this.fetchCollection(), this.fetchHidden(), this.fetchMerch()])
                        .then(() => {
                            this.setState(
                                {
                                    hasContent: this.state.itemsCreated.length > 0 || this.state.itemsOwned.length > 0 || this.state.itemsCollection.length > 0 || this.state.itemsHidden.length > 0,
                                },
                                () => {
                                    Util.notify.info("Re-sync has been started - this can take a few minutes to complete, please check back soon.");
                                }
                            );
                        })
                        .catch((e) => {
                            console.error(e);
                            Util.notify.error("Failed to re-get your profile data. Please try again later.");
                        });
                })
                .catch((e) => {
                    console.error(e);
                    Util.notify.error("Ran into an issue during the re-sync. Please try again later.");
                });
        };

        getSocialIcon = (type) => {
            switch (type) {
                case "FACEBOOK":
                    return faFacebookF;
                case "TWITTER":
                    return faTwitter;
                case "INSTAGRAM":
                    return faInstagram;
                case "LINKEDIN":
                    return faLinkedinIn;
                case "TIKTOK":
                    return faTiktok;
                case "TELEGRAM":
                    return faTelegramPlane;
                case "DISCORD":
                    return faDiscord;
                case "SNAPCHAT":
                    return faSnapchatGhost;
                case "CUSTOM":
                    return faGlobe;
                default:
                    return faGlobe;
            }
        };

        onCopyPublicAddress = (address) => {
            if (address) {
                navigator.clipboard.writeText(address);
                Util.notify.info(`Copied address ${Common.cropTo(address, 18)} to clipboard.`);
            }
        };

        goToCreated = () => {
            window.scrollTo({
                top: this.created_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };
        goToOwned = () => {
            window.scrollTo({
                top: this.owned_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };
        goToCollections = () => {
            window.scrollTo({
                top: this.collection_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };
        goToHidden = () => {
            window.scrollTo({
                top: this.hidden_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };

        onShowBidOverlay = (listing) => {
            if (this.main_ref) {
                this.main_ref.current.showBidOverlay(listing);
            }
        };

        render() {
            return !this.state.profile ? (
                <Main
                    title={"BMinted"}
                    initialising={this.state.initialising}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <NotFoundPage notFoundName={"Profile"} />
                </Main>
            ) : (
                <Main
                    ref={this.main_ref}
                    initialising={this.state.initialising}
                    title={"BMinted"}
                    auth={this.props.auth}
                    prices={this.props.prices}
                    providers={this.props.providers}
                    currentChain={this.props.currentChain}
                    chains={this.props.chains}
                >
                    <ContentArea>
                        <div className={styles.header_container}>
                            <div className={styles.options_container}>
                                {Common.objectPropertyExists(this.state, "profile.socialLinks", []).map((link, index) => (
                                    <SocialButton key={`social_sect_${index}`} darkMode={false} link={link} />
                                ))}
                                <span className={styles.options_break} />
                                {!this.state.thisIsMyProfile && <SocialButton darkMode={false} name={"Follow"} onClick={this.toggleFollow} icon={this.state.isFollowing ? faHeartSolid : faHeart} />}
                                <DropdownMenu
                                    iconComponent={<SocialButton darkMode={false} name={"Share"} icon={faShareNodes} />}
                                    pin={"right"}
                                    items={[
                                        // {
                                        //     type: "item",
                                        //     onClick: this.onShareFacebook,
                                        //     icon: faFacebookF,
                                        //     name: "Share on Facebook",
                                        // },
                                        {
                                            type: "item",
                                            onClick: this.onShareByEmail,
                                            icon: faEnvelope,
                                            name: "Share by Email",
                                        },
                                        {
                                            type: "item",
                                            onClick: this.onShareByUrl,
                                            icon: faLink,
                                            name: "Copy Profile Url",
                                        },
                                    ]}
                                />
                                <DropdownMenu
                                    iconComponent={<SocialButton darkMode={false} name={"Menu"} icon={faBars} />}
                                    pin={"right"}
                                    items={[
                                        ...(this.state.thisIsMyProfile
                                            ? [
                                                  {
                                                      type: "link",
                                                      to: `/u/edit/${this.props.params.displayname}`,
                                                      icon: faPencilAlt,
                                                      name: "Edit Profile",
                                                  },
                                                  {
                                                      type: "link",
                                                      to: `/m/create?scheme=${this.state.profile.scheme}`,
                                                      icon: faPlus,
                                                      name: "Add Media",
                                                  },
                                                  {
                                                      type: "item",
                                                      onClick: this.onSyncWithChain,
                                                      icon: faRotate,
                                                      name: `Re-Sync With Chain (${window.ethereum.chainId})`,
                                                  },
                                                  {
                                                      type: "break",
                                                  },
                                              ]
                                            : []),
                                        ...(Common.objectPropertyExists(this.state, "profile.addresses", []).length > 0
                                            ? Common.objectPropertyExists(this.state, "profile.addresses", []).map((address, index) => {
                                                  return {
                                                      type: "item",
                                                      name: Common.cropTo(address.publicAddress, 25),
                                                      icon: faEthereum,
                                                      onClick: () => {
                                                          this.onCopyPublicAddress(address.publicAddress);
                                                      },
                                                  };
                                              })
                                            : []),
                                        ...(Common.objectPropertyExists(this.state, "profile.addresses", []).length > 0
                                            ? [
                                                  {
                                                      type: "break",
                                                  },
                                              ]
                                            : []),
                                        {
                                            type: "item",
                                            name: "Report",
                                            icon: faFlag,
                                            onClick: this.showReportOverlay,
                                        },
                                    ]}
                                />
                            </div>
                            <div className={styles.profile_image_container}>
                                <Avatar user={this.state.profile} large={true} />
                                {Common.objectPropertyExists(this.state, "profile.scheme", null) === "BGROOVIA" && <img className={styles.drips} src={image_drips_tall} />}
                            </div>
                            <div className={styles.profile_name_container}>
                                <p>{Common.objectPropertyExists(this.state, "profile.alias", Common.objectPropertyExists(this.state, "profile.displayName", "Hello :)"))}</p>
                                <div className={styles.profile_description}>
                                    {Common.objectPropertyExists(this.state, "profile.bio", null) &&
                                        this.state.profile.bio.split("\n").map((line, index) => {
                                            return !!line && line.length > 0 && <p key={`pl_${index}`}>{line}</p>;
                                        })}
                                </div>
                            </div>
                        </div>
                    </ContentArea>

                    <ContentArea slim={true}>
                        {this.state.isBgrooviaProfile ? (
                            <Statistics
                                stats={{
                                    Followers: Common.numberFormat(this.state.followerCount),
                                    Music: 0,
                                    Albums: 0,
                                    Merch: 0,
                                }}
                            />
                        ) : (
                            <Statistics
                                stats={{
                                    Followers: Common.numberFormat(this.state.followerCount),
                                    // Following: Common.numberFormat(this.state.followingCount),
                                    Created: 0,
                                    Collected: 0,
                                    Collections: 0,
                                }}
                            />
                        )}
                    </ContentArea>

                    <span ref={this.created_section_ref} />
                    {this.state.hasFetchedCreated && this.state.itemsCreated.length > 0 && (
                        <ContentArea header={"Created."} stickyHeader={true}>
                            <MasonryTiles
                                data={this.state.itemsCreated}
                                onLoadMore={this.fetchCreated}
                                hasMoreToFetch={this.state.hasMoreCreated}
                                showLoadMore={true}
                                loadMoreButtonText={"Load More Created"}
                                windowSize={this.state.windowSize}
                                showExploreMarket={false}
                                onBid={this.onShowBidOverlay}
                            />
                        </ContentArea>
                    )}

                    <span ref={this.owned_section_ref} />
                    {this.state.hasFetchedOwned && this.state.itemsOwned.length > 0 && (
                        <ContentArea header={"Collected."} stickyHeader={true}>
                            <MasonryTiles
                                data={this.state.itemsOwned}
                                onLoadMore={this.fetchOwned}
                                hasMoreToFetch={this.state.hasMoreOwned}
                                showLoadMore={true}
                                loadMoreButtonText={"Load More Collected"}
                                windowSize={this.state.windowSize}
                                showExploreMarket={false}
                                onBid={this.onShowBidOverlay}
                            />
                        </ContentArea>
                    )}

                    <span ref={this.collection_section_ref} />
                    {this.state.hasFetchedCollection && this.state.itemsCollection.length > 0 && (
                        <ContentArea header={"Collections."} stickyHeader={true}>
                            <MasonryTiles
                                data={this.state.itemsCollection}
                                onLoadMore={this.fetchCollection}
                                hasMoreToFetch={this.state.hasMoreCollection}
                                showLoadMore={true}
                                loadMoreButtonText={"Load More Collections"}
                                windowSize={this.state.windowSize}
                                showExploreMarket={false}
                                onBid={this.onShowBidOverlay}
                            />
                        </ContentArea>
                    )}

                    <span ref={this.hidden_section_ref} />
                    {this.state.thisIsMyProfile && this.state.hasFetchedHidden && this.state.itemsHidden.length > 0 && (
                        <ContentArea header={"Hidden."} subHeader={"Only visible to you."} stickyHeader={true}>
                            <MasonryTiles
                                data={this.state.itemsHidden}
                                onLoadMore={this.fetchHidden}
                                hasMoreToFetch={this.state.hasMoreHidden}
                                showLoadMore={true}
                                loadMoreButtonText={"Load More Hidden"}
                                windowSize={this.state.windowSize}
                                showExploreMarket={false}
                                onBid={this.onShowBidOverlay}
                            />
                        </ContentArea>
                    )}

                    {!this.state.hasContent && (
                        <ContentArea>
                            {this.state.thisIsMyProfile ? (
                                <MissingDataPanel
                                    title={"We'll fetch your nfts..."}
                                    message={
                                        "We don't currently have anything to display here, if you've just signed up we'll automatically fetch your nfts, (if they don't show then use the account sync in the top right menu!), this action can take a while so please be patient! :)"
                                    }
                                    button={<Button displayMode={6} hoverMode={3} text={"Explore Market"} to={`/explore`} large={true} style={{ margin: 0 }} />}
                                />
                            ) : (
                                <MissingDataPanel title={"Nothing to Display"} message={"Check back soon!"} />
                            )}
                        </ContentArea>
                    )}

                    <NavPanel
                        showScrollTop={this.state.hasContent}
                        tabs={[
                            ...(this.state.hasFetchedCreated && this.state.itemsCreated.length > 0
                                ? [
                                      {
                                          onClick: this.goToCreated,
                                          text: "Created",
                                      },
                                  ]
                                : []),
                            ...(this.state.hasFetchedOwned && this.state.itemsOwned.length > 0
                                ? [
                                      {
                                          onClick: this.goToOwned,
                                          text: "Collected",
                                      },
                                  ]
                                : []),
                            ...(this.state.hasFetchedCollection && this.state.itemsCollection.length > 0
                                ? [
                                      {
                                          onClick: this.goToCollections,
                                          text: "Collections",
                                      },
                                  ]
                                : []),
                            ...(this.state.thisIsMyProfile && this.state.hasFetchedHidden && this.state.itemsHidden.length > 0
                                ? [
                                      {
                                          onClick: this.goToHidden,
                                          text: "Hidden",
                                      },
                                  ]
                                : []),
                            ...(this.state.isBgrooviaProfile && this.state.hasFetchedMerch && this.state.itemsMerch.length > 0
                                ? [
                                      {
                                          onClick: this.goToMerch,
                                          text: "Merch",
                                      },
                                  ]
                                : []),
                        ]}
                    />

                    {this.state.showAccountVerification && <AccountVerifyOverlay user={this.props.auth.user} onClose={this.hideAccountVerificationOverlay} />}
                    {this.state.showReportOverlay && <ReportOverlay contentType={"PROFILE"} contentId={this.props.params.displayname} onClose={this.hideReportOverlay} />}
                </Main>
            );
        }
    }
);
