// extracted by mini-css-extract-plugin
export var action_content = "index-module--action_content--WJZ7m";
export var bio_container = "index-module--bio_container--t95aW";
export var button_container = "index-module--button_container--faFEi";
export var detail_container = "index-module--detail_container--ro+Dy";
export var drips = "index-module--drips--SeqjD";
export var edit_container = "index-module--edit_container--wPiUN";
export var follower_stats = "index-module--follower_stats--uflgM";
export var follower_stats_container = "index-module--follower_stats_container--X1iU1";
export var header_container = "index-module--header_container--InqBL";
export var left_side = "index-module--left_side--C4dAk";
export var link_container = "index-module--link_container--kBIc+";
export var name_container = "index-module--name_container--Pay--";
export var options_break = "index-module--options_break--YBnLW";
export var options_container = "index-module--options_container--YITX8";
export var profile_button = "index-module--profile_button--ir1+b";
export var profile_description = "index-module--profile_description--HgtcS";
export var profile_image = "index-module--profile_image--numyh";
export var profile_image_container = "index-module--profile_image_container--JXEDb";
export var profile_name_container = "index-module--profile_name_container--X7D9k";
export var user_links_address = "index-module--user_links_address--PzShq";
export var user_links_address_icon = "index-module--user_links_address_icon--Y2zWN";
export var user_links_address_icon_container = "index-module--user_links_address_icon_container--fuLwm";
export var user_links_address_text = "index-module--user_links_address_text--qkSEh";
export var user_links_container = "index-module--user_links_container--SOLIs";
export var user_links_share = "index-module--user_links_share--xLCER";
export var vertical_rule = "index-module--vertical_rule--6yCmf";