import React from "react";
import * as styles from "./midPageHeader.module.css";

export default class MidPageHeader extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <div className={styles.container}>
                <div className={styles.inner_container}>
                    {this.props.headers
                        .filter((h) => {
                            return h !== null;
                        })
                        .map((header, index) => (
                            <h3 key={`header_${index}`} onClick={header.onClick} className={header.selected ? styles.selected : ""}>
                                {header.name}
                            </h3>
                        ))}
                </div>
            </div>
        );
    }
}
